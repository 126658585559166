/**
 * Created by pshivaraman on 3/23/18.
 */
import React, {Component} from "react";
import {ROLES, TOKEN_KEY} from "../actions/auth_actions";
import HomePage from "./home";
import {CommonUtils} from "../actions/common_utils";
import {CURR_LANDING_PAGE, CUR_ORG, ORG_LANDING_PATH, SYS_LANDING_PATH} from "../actions/session_constants";

class MainApp extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // If authenticated
        if (sessionStorage.getItem(TOKEN_KEY)) {
            // Go to System Page
            if (SYS_LANDING_PATH === sessionStorage.getItem(CURR_LANDING_PAGE)){
                this.props.history.push("/sys/dash");
            }
            if (ORG_LANDING_PATH === sessionStorage.getItem(CURR_LANDING_PAGE)){
                sessionStorage.removeItem(CUR_ORG);
                this.props.history.push("/org/list");
            }
            if (CommonUtils.hasRole('HR_MGR') || CommonUtils.hasRole('ORG_ADMIN') ||
                CommonUtils.hasRole('PRG_MGR') || CommonUtils.hasRole('SERV_CTR') ||
                CommonUtils.hasRole('CONTRACT_PRG_MGR') || CommonUtils.hasRole('CONTRACT_ADMIN')) {
                // Go to Organization List Page
                sessionStorage.setItem(CURR_LANDING_PAGE, ORG_LANDING_PATH);
                sessionStorage.removeItem(CUR_ORG);
                this.props.history.push("/org/list");
            } else if (CommonUtils.hasSysAdminRole()){
                sessionStorage.setItem(CURR_LANDING_PAGE, SYS_LANDING_PATH);
                this.props.history.push("/sys/dash");
            }

        }
    }


    render() {
        return (
            <HomePage {...this.props}/>
        )
    }
}

export default MainApp;