/**
 * Created by Bharath on 17/08/23.
 */

import React, {Fragment, Component} from "react";
import Container from "react-bootstrap/Container";

import TruVettingLogoLight from "../assets/images/TruVetting-Logo-Light.png";

import trustblue from "../assets/images/icons/trust.png";
import verifyblue from "../assets/images/icons/verify.png";
import secureblue from "../assets/images/icons/secure.png";
import successblue from "../assets/images/icons/success.png";
import Image from "react-bootstrap/Image";
import {Link} from "react-router-dom";



class FaqPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="reporting_bg p-0">



  <Fragment>
                <Container className="Inter">

                <div style={{textAlign: "center"}}>
                        <Image src={TruVettingLogoLight} className="l_logo" fluid/>


                        <div className="top_pillars"> <span><v>T</v>rust</span>  <span><v>V</v>erify</span> < span><v>S</v>ecure</span> <span><v>S</v>ucceed</span></div>
                       
                    </div>
                <div class="faq_section">

                <div class="heading_container"><h3>FAQ's</h3></div>
        <div class="fmenu">

        

            <li class="fitem" id="q1">
                <a href="#q1" class="fbtn"><i class="fa-solid fa-database" aria-hidden="true"></i>Where is Data Stored?</a>
                <div class="fsmenu">
                    <p>Data from Tru-Vetting is stored via AQS S3 & AWS MongoDB.</p>
                </div>
            </li>
            <li class="fitem" id="q2">
                <a href="#q2" class="fbtn"> <i class="fa-solid fa-database" aria-hidden="true"></i>How is Data Stored?</a>
                <div class="fsmenu">
                    <p>Data is stored as files &amp; documents.</p>
                </div>
            </li>
            <li class="fitem" id="q3">
                <a href="#q3" class="fbtn"><i class="fa-solid fa-database" aria-hidden="true"></i>How is data separated?</a>
                <div class="fsmenu">
                    <p>Data is separated by organization. An organization’s data is data & documents on subjects,
facilities, contracts, documents of an organization.</p>
                </div>
            </li>

            <li class="fitem" id="q4">
                <a href="#q4" class="fbtn"><i class="fa-solid fa-database" aria-hidden="true"></i>Is Data at Rest Encrypted?</a>
                <div class="fsmenu">
                    <p>Yes, encryption at Rest is done in MongoDB Atlas. Atlas is configured to use AWS Key
management service and encryption at Rest is accomplished with the WiredTiger Encrypted Storage
Engine. All cluster storage and snapshot volumes are encrypted this way.</p>
                </div>
            </li>

            <li class="fitem" id="q5">
                <a href="#q5" class="fbtn"><i class="fa-solid fa-database" aria-hidden="true"></i>Who has visibility to data?</a>
                <div class="fsmenu">
                    <p>An organization’s users have access to the data/information they have uploaded into the
system. Service Centers (Like PerSecPros) who support an organization have access to the
organization’s data. Users are controlled by Role Bases Access Control and defined to have access to
specific information within the organization.</p>
                </div>
            </li>
           
        </div>

        <div className="align-right mt-20">
            <Link className="btn btn-danger" to={"/"}>Back</Link>
        </div>


    </div>
                </Container>
            </Fragment>
            </div>
          
        )
    }
}

export default FaqPage;