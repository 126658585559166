/**
 * Created by pshivaraman on 7/11/19.
 */

import React, { Fragment, Component } from "react";
import Container from "react-bootstrap/Container";
import Carousel from 'react-bootstrap/Carousel';

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import TruVettingLogoLight from "../assets/images/TruVetting-Logo-Light.png";
import banner from "../assets/images/home/banner_updated.png";
import image1 from "../assets/images/home/image.jpg";
import fso from "../assets/images/home/FSO.gif";
import Hire from "../assets/images/home/Hire.gif";
import Metrics from "../assets/images/home/Metrics.gif";
import Portal from "../assets/images/home/Portal.gif";
import logo_overlay from "../assets/images/home/logo_bg_overlay.png";
import footer_banner from "../assets/images/home/footer_banner.png"
import HomepageLogo from "../assets/images/logo_icon.png";
import SampleThumb1 from "../assets/images/sample_thumbnails/thumb1.png";
import SampleThumb2 from "../assets/images/sample_thumbnails/thumb2.png";
import SampleThumb3 from "../assets/images/sample_thumbnails/thumb3.png";
import SampleThumb4 from "../assets/images/sample_thumbnails/thumb4.png";
import SampleThumb5 from "../assets/images/sample_thumbnails/thumb5.png";
import trustblue from "../assets/images/icons/trust.png";
import trustred from "../assets/images/icons/trust_red.png";
import verifyblue from "../assets/images/icons/verify.png";
import verifyred from "../assets/images/icons/verify_red.png";
import secureblue from "../assets/images/icons/secure.png";
import securered from "../assets/images/icons/security_red.png";
import successblue from "../assets/images/icons/success.png";
import successred from "../assets/images/icons/success_red.png";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";

import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import {DISPLAY_NAME, logoutUser, ROLES, TOKEN_KEY, USER_NAME} from "../actions/auth_actions";
import {CUR_ORG, CURR_LANDING_PAGE, ORG_LANDING_PATH, SYS_LANDING_PATH} from "../actions/session_constants";
import {CommonUtils} from "../actions/common_utils";
import { Link, Element, animateScroll as scroll } from 'react-scroll';



class HomePage extends Component {
    constructor(props) {
        super(props);
    }

    onMenuItemSelect(key) {
        switch (key) {
            case "1.3":
                logoutUser();
                break;
            case "3":
                sessionStorage.removeItem(CUR_ORG);
                break;
            case "5":
                sessionStorage.setItem(CURR_LANDING_PAGE, ORG_LANDING_PATH);
                break;
            case "6":
                // sessionStorage.removeItem(CUR_ORG);
                sessionStorage.setItem(CURR_LANDING_PAGE, SYS_LANDING_PATH);
                break;
            case "1.1":
                this.setState({passwordModel: true});
                break; 
            case "1.2":
                this.setState({notificationModel: true});
                break; 
        }
    }

    switchOrgButton() {
        let path = this.props.location.pathname;
        let isInSysAdmin = false;
        if (path.startsWith('/sys/')) {
            isInSysAdmin = true;
        }
        if (sessionStorage.getItem(TOKEN_KEY) && !isInSysAdmin && sessionStorage.getItem(CUR_ORG)) {
            return (
                <Nav.Item>
                    <LinkContainer to="/org/list" exact>
                        <Nav.Link eventKey={3}><i className="fa fa-fw fa-exchange"/> Switch Organization</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            )
        }
    }

    switchRoleButton = () => {
        // If user has both org_admin and sys_admin roles
        if (CommonUtils.hasSysAdminRole() && CommonUtils.hasRole('ORG_ADMIN')) {
            // if user is logged in as org_admin then allow them to choose to switch to sys admin
            let path = this.props.location.pathname;
            if (path.startsWith('/sys/')){
                return (
                    <Nav.Item>
                        <LinkContainer to="/org/list" exact>
                            <Nav.Link eventKey={5}><i className="fa fa-fw fa-exchange"/> Switch to Org Admin Role</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                );
            } else {
                // .. and vice versa
                return (
                    <Nav.Item>
                        <LinkContainer to="/sys/dash" exact>
                            <Nav.Link eventKey={6}><i className="fa fa-fw fa-exchange"/> Switch to Sys Admin Role</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                );
            }
        }
    }

    render() {
        return (
            <div className="reporting_bg p-0">
                <style>
                    {
                        ` .tooltip-inner {
            background: linear-gradient(130deg, #be132f, #002e5d);
            color: #000;
            max-width: 90%;
            position: relative;
            text-align: left;
            font-size: 15px;
            opacity: 1;
            margin: auto;
            color: #fff;
            padding: 10px 10px;
            min-height: 220px;
          }`
                    }

                </style>

                <Fragment>
                    <div className="Inter container-fluid p-0">

                        <Navbar className="bg-white home-page-nav" expand={'lg'}  variant={'light'} fixed={'top'}>
                            <Navbar.Brand className="left-nav-wrapper1">
                                <Link to="/">
                                    <img
                                        alt="Tru-Vetting"
                                        src={HomepageLogo}
                                        height="70"
                                    />
                                </Link>


                            </Navbar.Brand>

                            <Navbar.Toggle />

                            <Navbar.Collapse>
                                <Nav />
                                <Nav className={'ml-auto'} onSelect={this.onMenuItemSelect.bind(this)}>
                                    <Nav.Item>
                                        <Link to="home" exact>
                                            <Nav.Link className="blue"> Home </Nav.Link>
                                        </Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Link to="fso" smooth={true} duration={500} exact>
                                            <Nav.Link className="blue"> Featured Services </Nav.Link>
                                        </Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Link to="capabilities" exact>
                                            <Nav.Link className="blue"> Capabilities </Nav.Link>
                                        </Link>
                                    </Nav.Item>

                                     <Nav.Item>
                                        <LinkContainer to="/reporting/subject" exact>
                                            <Nav.Link className="blue"> My Security Management </Nav.Link>
                                        </LinkContainer>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <LinkContainer to="/login" exact>
                                            <Nav.Link className="blue"> Org Security Management </Nav.Link>
                                        </LinkContainer>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <LinkContainer to="/misc/contactUs" exact>
                                            <Nav.Link className="blue_btn"> Contact Us </Nav.Link>
                                        </LinkContainer>
                                    </Nav.Item>
                                </Nav>
                            </Navbar.Collapse>
                          

                        </Navbar>


                        <div className="banner_section" name="home">
                            <div className="banner_img">
                                <Image src={banner} className="banner" fluid />
                            </div>
                        </div>

                        <div className="section_2 p-5-5">
                            <div className="col-lg-12 row align-items-center">
                                <div className="col-lg-6">
                                    <div className="content_section">
                                        <div className="home_title">
                                            THE <span>TRU</span>-V<span>ETTING</span> <br></br>DIFFERENCE
                                        </div>
                                        <div className="home_content">
                                            <p>Created by professionals with over 50 years of expertise, Tru-Vetting revolutionizes security management. Our solution enhances efficiency, turning complex security processes into simple operations. Tru-Vetting is your FSO companion, empowering your organization to focus on growth while keeping your business aligned with the latest policies and procedures.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="image_section">
                                        <Image src={image1} className="banner" fluid />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section_3 bg_blue padding-2-5">
                            <div className="fso">
                                <div className="fso_title">
                                    <h1>MADE BY FSOs FOR FSOs</h1>
                                </div>

                                <div className="col-lg-12 row p-0-5 m-0 fso_grid">
                                    <div className="fso_card">
                                        <div className="fso_content">
                                            <h4>Nick Levasseur</h4>
                                            <p>Nick Levasseur, an Army combat veteran and a respected expert in the personnel security mission space, focusing on compliance software for the National Industrial Security Program (NISP) and the cleared industry. As a certified adjudicator, he made critical security determinations and expertly navigated complex security landscapes. His roles as Industry Liaison for the Defense Counterintelligence and Security Agency (DCSA) and IT Analyst for the Defense Information System for Security (DISS) have provided him with unique insights into the intersection of security, technology, and industry. Since transitioning from civil service, Nick has remained dedicated to supporting cleared industry. His leadership drives Tru-Vetting’s mission to deliver top-notch compliance solutions tailored to the NISP and cleared industry needs.</p>
                                        </div>
                                    </div>

                                    <div className="fso_card">
                                        <div className="fso_content">
                                            <h4>Cory Klein</h4>
                                            <p>Cory Klein, a former Marine and co-founder of Personnel Security Professionals LLC, brings exceptional leadership and dedication to the field. With management and director-level experience in both large corporations and small enterprises, Cory has consistently demonstrated his subject matter expertise. Known for impactful training sessions and insightful briefings nationwide, Cory’s passion for excellence drives the ongoing success of Tru-Vetting, ensuring clients receive unparalleled expertise and support.</p>
                                        </div>
                                    </div>

                                    <div className="fso_card">
                                        <div className="fso_content">
                                            <h4>Kumar Gnanamurthy</h4>
                                            <p>Our Chief Executive Officer, Kumar Gnanamurthy, has over two decades of experience in technology consulting, business process optimization, talent development, and client relationship management. He has collaborated closely with the Department of Defense (DoD) as a subject matter expert, significantly contributing to personnel security and the National Security Operations mission. His expertise in Service Oriented Architecture (SOA), microservices, enterprise architecture, analytics, and AI has enabled him to architect solutions that maximize efficiency and streamline business processes. Kumar's achievements include architecting the DoD System of Record, implementing transformative technology solutions, and optimizing security measures, earning him a reputation for exceptional client relationships and innovative technological advancements. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section_4 padding-2-5" name="fso">
                            <div className="featured">
                                <div className="featured_title">
                                    <h1>FEATURED SERVICES</h1>
                                </div>

                                <div className="col-lg-12 row p-0-5 m-0 fso_grid">
                                    <div className="featured_card">
                                        <div className="featured_content">
                                            <div className="featured_gif">
                                                <Image src={fso} className="banner" fluid />
                                            </div>
                                            <h4>FSO Companion</h4>
                                            <p>
                                                <ul>
                                                    <li>Offering real-time AI assistance, FSO Companion acts as your on-demand consultant.</li>
                                                    <li>Leveraging the comprehensive knowledge of DISS, NBIS, and NISPOM (32 CFR 117) guidelines, FSO Companion gives reliable guidance on national security compliance, enhancing decision making and operational efficiency.</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="featured_card">
                                        <div className="featured_content">
                                            <div className="featured_gif">
                                                <Image src={Hire} className="banner" fluid />
                                            </div>
                                            <h4>New Hire Portal</h4>
                                            <p>
                                                <ul>
                                                    <li>The portal offers a secure, intuitive platform for managing security clearances and new hire documentation, eliminating the risks of unsecured emails and sensitive PII exposure.</li>
                                                    <li>Helps organizations align with Trusted Workforce 2.0 by ensuring that all new hires are thoroughly vetted and compliant with the latest security protocols.</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="featured_card">
                                        <div className="featured_content">
                                            <div className="featured_gif">
                                                <Image src={Metrics} className="banner" fluid />
                                            </div>
                                            <h4>Enhanced Dashboard and Compliance Metrics</h4>
                                            <p>
                                                <ul>
                                                    <li>Real time compliance metrics from your comprehensive dashboard eliminates the need for manual report generation and analysis.</li>
                                                    <li>Customizable notification center allows users to tailor when, how, and where they receive notifications, staying informed without feeling overwhelmed by constant alerts.</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="featured_card">
                                        <div className="featured_content">
                                            <div className="featured_gif">
                                                <Image src={Portal} className="banner" fluid />
                                            </div>
                                            <h4>Self Reporting Portal</h4>
                                            <p>
                                                <ul>
                                                    <li>Facilitates personnel in effortlessly reporting foreign travel, life changes, or any other information mandated by the 32 CFR and enables them to review and update all previously reported information as necessary.</li>
                                                    <li>Enables mass updates in DISS and NBIS through Streamlined Reporting and Unified Data Management.</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact_us">
                                    <a href="/misc/contactUs" className="btn btn-primary-home">Contact Us</a>
                                </div>
                            </div>
                        </div>

                        <div className="section_5 bg_grey padding-2-5" name="capabilities">
                            <div className="capabilities">
                                <div className="featured_title">
                                    <h1>CAPABILITIES</h1>
                                </div>

                                <div className="container px-2 px-md-3 mx-auto">
                                    <div className="row d-flex justify-content-center">

                                        <div className="col-md-12 col-lg-12 col-xl-12">
                                            <div className="border-0">

                                                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">

                                                    <div class="carousel-inner">

                                                        <div class="carousel-item active">
                                                            <div className="card capabilities_box border-0 card-0">
                                                                <div className="col-lg-12 row align-items-center">
                                                                    <div className="col-lg-6">
                                                                        <Image src={image1} className="banner" fluid />
                                                                    </div>
                                                                    <div className="col-lg-6">

                                                                        <div className="capabilities_content">
                                                                            <div className="overlay_image">
                                                                                <Image src={logo_overlay} className="banner" fluid />
                                                                            </div>
                                                                            <h1>Compliance Monitoring with Tru-Vetting</h1>
                                                                            <p>Streamline your security process with real-time compliance metrics, customizable notifications, and enhanced dashboard at a glance, all at your fingertips.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="carousel-item">
                                                            <div className="card border-0 card-0">
                                                                <div className="col-lg-12 row align-items-center">
                                                                    <div className="col-lg-6">
                                                                        <Image src={image1} className="banner" fluid />
                                                                    </div>
                                                                    <div className="col-lg-6">

                                                                        <div className="capabilities_content">
                                                                            <div className="overlay_image">
                                                                                <Image src={logo_overlay} className="banner" fluid />
                                                                            </div>
                                                                            <h1>FSO Services</h1>
                                                                            <p>Through a partnership with PerSecPros, a trusted DCSA Security Specialist, we offer FSO services using Tru-Vetting to meet a wide range of government and industrial security needs. Focus on what matters: we handle compliance, you drive growth.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class="carousel-item">
                                                            <div className="card border-0 card-0">
                                                                <div className="col-lg-12 row align-items-center">
                                                                    <div className="col-lg-6">
                                                                        <Image src={image1} className="banner" fluid />
                                                                    </div>
                                                                    <div className="col-lg-6">

                                                                        <div className="capabilities_content">
                                                                            <div className="overlay_image">
                                                                                <Image src={logo_overlay} className="banner" fluid />
                                                                            </div>
                                                                            <h1>New Hire Portal</h1>
                                                                            <p>The portal offers a secure, intuitive platform for managing security clearances and new hire documentation, eliminating the risks of unsecured emails and sensitive PII exposure. Effortlessly connect to your HR systems with our New Hire Portal, integrating with SaaS applications, AI services, and APIs</p>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>


                                                    </div>
                                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                        <span class="visually-hidden">Previous</span>
                                                    </button>
                                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                        <span class="visually-hidden">Next</span>
                                                    </button>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* <div className="section_6 padding-2-5">
                            <div className="featured_title">
                                <h1><span>TRU</span>-V<span>ETTING</span> IN ACTION</h1>
                            </div>
                            <div className="action">

                                <video width="700" controls>
                                    <source src="https://truvetting-ui-local.s3.amazonaws.com/tvHomePageVideo1.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div> */}

                        <div className="section_7">
                            <div className="footer_banner">
                                <Image src={footer_banner} className="banner" fluid />

                                <h3>Discover how Tru-Vetting can help your organization<br></br> succeed in trust, compliance, and security.</h3>

                                <div className="demo_btn">
                                    <a href="/misc/contactUs" className="btn btn-primary-home">Request a Demo</a>
                                </div>

                            </div>


                        </div>

                        <div className="section_8 padding-2 bg_black align-center">
                            <div className="copyright">
                                <p>© 2024 Tru-Vetting. All Rights Reserved</p>
                            </div>
                        </div>


                    </div>
                </Fragment>
            </div>

        )
    }
}

export default HomePage;